<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-07-27 14:41:39
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-08 13:36:41
 * @FilePath: \official_website\src\views\componets\Solution.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="solution">
    <div class="solution">
      <div class="left">
        <!-- wow animate__animated animate__fadeInUp -->
          <div class="title">
            产品及 <br />
            解决方案
          </div>
        <!-- wow animate__animated animate__fadeInUp -->
        <div class="description">创新精准医疗行业的前沿</div>
        <!-- wow animate__animated animate__backInLeft data-wow-delay="0.5s" -->
        <div class="arr-right">
          <i class="iconfont icon-youjiantou" @click="tapPages('/product')"></i>
        </div>
      </div>
      <div class="right">
        <div class="solution-box">
          <carousel-3d
            :autoplay="true"
            :autoplayTimeout="3000"
            :perspective="60"
            :display="3"
            :animationSpeed="1000"
            style="width: 100%; height: 700px"
            :width="700"
            :height="585"
            controlsVisible
            :controlsHeight="60"
            :space="800"
          >
            <slide v-for="(item, i) in slides" :index="i" :key="i">
              <template slot-scope="obj">
                <img :src="item.src" @click="imgClick(item, obj)" />
                <div class="describe">
                  <div class="title" @click="imgClick(item, obj)">
                    {{ item.title }}
                  </div>
                  <!-- <div class="arr-btn"></div> -->
                </div>
              </template>
            </slide>
          </carousel-3d>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  name: "",
  props: {},
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      slides: [
        {
          title: "DNA倍体癌症早筛解决方案",
          path: "/product/dna",
          src: require("@/assets/image/home/solution1.png"),
        },
        {
          title: "血液细胞形态诊断解决方案",
          path: "/product/blood",
          src: require("@/assets/image/home/solution2.png"),
        },
        {
          title: "宫颈癌筛查解决方案",
          path: "/product/tbs",
          src: require("@/assets/image/home/solution3.png"),
        },
        {
          title: "远程病理诊断解决方案",
          path: "/product/telepathology",
          src: require("@/assets/image/home/solution4.png"),
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {
    imgClick(data, obj) {
      this.$router.push(data.path);
    },
    tapPages(path) {
      this.$router.push(path);
    },
  },
};
</script>
    
<style lang="scss">
@media screen and (max-width: 1680px) {
  .solution-box {
    zoom: 0.9;
  }
  .carousel-3d-container {
    zoom: 0.9;
  }
  .carousel-3d-slider {
    zoom: 0.9;
  }
}
#solution {
  width: 100%;
  height: 100%;
  .solution {
    display: flex;
    width: 100%;
    height: 100%;
    // align-items: center;
    .left {
      width: 20%;
      height: 100%;
      padding-top: 8%;
      .title {
        font-size: 2rem;
        margin-bottom: 1rem;
        font-family: "SourceHanSansSC-Medium";
      }
      .description {
        font-size: 1.2rem;
        width: 11.875rem;
        color: #444;
        margin-bottom: 4rem;
        font-family: "OPlusSans3-Regular";
      }
      .arr-right {
        i {
          font-size: 6rem;
          color: #0b847b;
          cursor: pointer;
        }
      }
    }
    .right {
      flex: 1;
      height: 100%;
      // padding-top: 6%;
      .solution-box {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        // background-color: #ccc;
        .carousel-3d-container {
          padding-top: 3rem;
          .carousel-3d-slide {
            background-color: transparent;
            border-radius: 1.5rem;
            border-color: transparent;
            cursor: pointer;
            .describe {
              width: 100%;
              height: 3.75rem;
              // position: absolute;
              // left: 0;
              // bottom: -1rem;
              padding: 0 2.5rem;
              display: flex;
              align-items: center;
              // justify-content: space-between;
              justify-content: center;
              .title {
                font-size: 1.8rem;
                // color: #fff;
                font-family: "SourceHanSansSC-Medium";
              }
            }
          }
          // .current {
          //   animation-name: scaleSolution; /*关键帧名称*/
          //   animation-timing-function: ease; /*动画的速度曲线*/
          //   animation-duration: 3s; /*动画所花费的时间*/
          //   background-size: 105%;
          // }
          .left-1 {
            opacity: 0.4 !important;
          }
          .right-1 {
            opacity: 0.4 !important;
          }
          .next,
          .prev {
            color: #0b847b;
            span {
              font-size: 5rem;
            }
          }
          .carousel-3d-controls {
            top: 45%;
          }
        }
      }
    }
  }
  // @keyframes scaleSolution {
  //   /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  //   0% {
  //     transform: scale(1); /*开始为原始大小*/
  //   }
  //   100% {
  //     transform: scale(1.1);
  //   }
  // }
}
</style>